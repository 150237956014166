import { instances } from '@/utils/httpAaser.js'
import { instance } from '@/utils/http.js'
// 获取科目
export const getSubjectName = function (params) {
  return instance({
    url: '/api/v1/stucard/subject',
    method: 'get',
    params
  })
}

// 获取随机生成的题目列表
export const getTopicList = function (params) {
  return instances({
    url: '/api/v1/funny_train/question_list',
    method: 'get',
    params
  })
}

// 获取某一批的题目列表
export const getSinTopicList = function (params) {
  return instances({
    url: '/api/v1/funny_train/question_list/continue',
    method: 'get',
    params
  })
}

// 获取排行信息
export const getRank = function (params) {
  return instances({
    url: '/api/v1/funny_train/rank',
    method: 'get',
    params
  })
}

// 获取答案详情
export const getDetail = function (params) {
  return instances({
    url: '/api/v1/funny_train/report',
    method: 'get',
    params
  })
}

// 获取名篇名句默写
export const getArticle = function (params) {
  return instances({
    url: '/api/v1/recited_article',
    method: 'get',
    params
  })
}

// 提交名篇名句默写
export const subArticle = function (params) {
  return instances({
    url: '/api/v1/recited_article/submit',
    method: 'post',
    params
  })
}

// 获取随机生成的单词列表
export const getWordList = function (params) {
  return instances({
    url: '/api/v1/recited_word/list',
    method: 'get',
    params
  })
}

// 获取单词记忆答案详情
export const getWordDetial = function (params) {
  return instances({
    url: '/api/v1/recited_word/report',
    method: 'get',
    params
  })
}

// 获取物理知识记忆
export const getPhysicalMemory = function (params) {
  return instances({
    url: '/api/v1/physical_knowledge',
    method: 'get',
    params
  })
}

//添加答案-单词记忆
export const subWord = function (params) {
  return instances({
    url: '/api/v1/recited_word/save',
    method: 'post',
    params
  })
}

//添加答案
export const subTrain = function (params) {
  return instances({
    url: '/api/v1/funny_train/save',
    method: 'post',
    params
  })
}
